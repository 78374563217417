import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { ContactPage } from '../components/pages';

const Contact: React.FC = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { page: { eq: "contact" } }) {
        frontmatter {
          primaryColor
          secondaryColor
          title
          subTitle
          heroText
          street
          city
          country
          mapLink
          phone1
          phone2
          email
        }
      }

      file(relativePath: { eq: "posters/contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const textData = data.markdownRemark.frontmatter;
  const { fluid } = data.file.childImageSharp;
  const pageData = { ...textData, fluid };

  return <ContactPage data={pageData} />;
};

export default Contact;
